<template>
    <div class="link-account" v-loading="loading">
        <div class="content-box">
            <div class="logo-wrap-box">
                <rap-logo class="logo"></rap-logo>
                <img src="@/assets/rap/link-img.svg" alt="">
            </div>
            <div class="title-wrap center">
                <label>CONNECT A STORE</label>
                <div class="tips">Enter your existing Shopify store RUL below and you’ll be redirected to Shopify to connect your account to Rapiddeals.</div>
            </div>
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
                <el-form-item prop="shopifyUrl">
                    <el-input
                        v-model="ruleForm.shopifyUrl"
                        placeholder="your Shopify URL"
                        clearable>
                        <template slot="prepend">https://</template>
                        <template slot="append">.myshopify.com</template>
                    </el-input>
                </el-form-item>
            </el-form>
            <el-button
                class="login-btn"
                type="primary"
                @click="doConnect">
                Connect
            </el-button>
            <el-button
                class="login-btn"
                @click="doCancel">
                Cancel
            </el-button>
            <div class="title-wrap" style="margin: 32px 0;">
                <div class="tips" style="text-align: left;">How to connect my Shopifystore ? </div>
                <div class="tips" style="text-align: left;">Do you integrate and other platform other than Shopify?</div>
                <div class="tips" style="text-align: left; margin-top: 18px;">haven’t created a store yet ? Learn more on</div>
                <span style="text-align: center;">
                    <el-link class="shopify">Shopify .com</el-link>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import RapLogo from '@/components/layout/logo.vue'
/* eslint-disable */
export default {
    components: {
        RapLogo
    },
    data () {
        return {
            ruleForm: {
                shopifyUrl: ''
            },
            rules: {
                shopifyUrl: [{ required: true, message: 'URL cannot be empty', trigger: 'change' }]
            },
            loading: false
        }
    },
    methods: {
        doConnect () {
            console.log('11')
            this.$refs.ruleForm &&
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.reqLinkAccount()
                }
            })
        },
        doCancel () {
            this.$router.push({ path: '/product' })
        },
        reqLinkAccount () {
            this.loading = true
            this.$api.bindShopify({
                url: this.ruleForm.shopifyUrl || ''
            }).then(res => {
                this.loading = false
                if (res.code === 0 && res.data) {
                    const data = res.data
                    const path = `${data.url}?client_id=${data.client_id}&grant_options[]=${data.grant_options}&scope=${data.scope}&status=${data.status || ''}&redirect_uri=${window.location.origin}`
                    console.log('path----:', path)
                    window.open(path)
                } else {
                    this.$message.error(res.message)
                }
            }).catch(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .link-account{
        display: flex;
        justify-content: center;
        align-items: center;
        height: $mainHeight;
        .content-box{
            width: 480px;
            padding: 10px;
        }
        .logo-wrap-box {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 38px;
            .logo {
                margin-right: 23px;
            }
        }
        .title-wrap{
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            &.center{
                align-items: center;
            }
            label{
                height: 45px;
                font-size: 32px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: rgba(0,0,0,0.85);
                line-height: 45px;
            }
            .tips{
                font-size: 16px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
            }
            .shopify{
                margin-top: 56px;
                color: #1890ff !important;
            }
            .shopify::after {
                border-color: #1890ff !important;
            }
        }
        .login-btn{
            width: 100%;
        }
        .el-button{
            margin: 0 0 20px 0;
        }
        /deep/ .el-input-group__prepend {
            padding: 0 5px 0 10px;
        }
        /deep/ .el-input-group__append {
            width: 40%;
            padding: 5px;
        }
    }
</style>
